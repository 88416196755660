:root, .light, .light-theme {
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: color(display-p3 0.986 0.992 0.999);
      --blue-2: color(display-p3 0.96 0.979 0.998);
      --blue-3: color(display-p3 0.912 0.956 0.991);
      --blue-4: color(display-p3 0.853 0.932 1);
      --blue-5: color(display-p3 0.788 0.894 0.998);
      --blue-6: color(display-p3 0.709 0.843 0.976);
      --blue-7: color(display-p3 0.606 0.777 0.947);
      --blue-8: color(display-p3 0.451 0.688 0.917);
      --blue-9: color(display-p3 0.247 0.556 0.969);
      --blue-10: color(display-p3 0.234 0.523 0.912);
      --blue-11: color(display-p3 0.15 0.44 0.84);
      --blue-12: color(display-p3 0.102 0.193 0.379);
    }
  }
}