button {
  all: unset;
}

.TagsDropdownSelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.TagsDropdownSelectTrigger:hover {
  background-color: var(--mauve-3);
}
.TagsDropdownSelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.TagsDropdownSelectTrigger[data-placeholder] {
  color: var(--violet-9);
}

.TagsDropdownSelectIcon {
  color: Var(--violet-11);
}

.TagsDropdownSelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.TagsDropdownSelectViewport {
  padding: 5px;
}

.TagsDropdownSelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.TagsDropdownSelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.TagsDropdownSelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet-9);
  color: var(--violet-1);
}

.TagsDropdownSelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.TagsDropdownSelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.TagsDropdownSelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.TagsDropdownSelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}
