*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root, .light, .light-theme {
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --mauve-1: #fdfcfd;
      --mauve-2: #faf9fb;
      --mauve-3: #f2eff3;
      --mauve-4: #eae7ec;
      --mauve-5: #e2dfe6;
      --mauve-6: #dbd8e0;
      --mauve-7: #d0cdd7;
      --mauve-8: #bcbac7;
      --mauve-9: #8e8c99;
      --mauve-10: #83818e;
      --mauve-11: #65636d;
      --mauve-12: #211f26;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --mauve-1: color(display-p3 .991 .988 .992);
        --mauve-2: color(display-p3 .98 .976 .984);
        --mauve-3: color(display-p3 .946 .938 .952);
        --mauve-4: color(display-p3 .915 .906 .925);
        --mauve-5: color(display-p3 .886 .876 .901);
        --mauve-6: color(display-p3 .856 .846 .875);
        --mauve-7: color(display-p3 .814 .804 .84);
        --mauve-8: color(display-p3 .735 .728 .777);
        --mauve-9: color(display-p3 .555 .549 .596);
        --mauve-10: color(display-p3 .514 .508 .552);
        --mauve-11: color(display-p3 .395 .388 .424);
        --mauve-12: color(display-p3 .128 .122 .147);
      }
    }
  }
}

:root, .light, .light-theme {
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --violet-1: #fdfcfe;
      --violet-2: #faf8ff;
      --violet-3: #f4f0fe;
      --violet-4: #ebe5ff;
      --violet-5: #e1d9ff;
      --violet-6: #d4cafe;
      --violet-7: #c2b5f5;
      --violet-8: #aa99ec;
      --violet-9: #6e56cf;
      --violet-10: #654dc4;
      --violet-11: #6550b9;
      --violet-12: #2f265f;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --violet-1: color(display-p3 .991 .988 .995);
        --violet-2: color(display-p3 .978 .974 .998);
        --violet-3: color(display-p3 .953 .943 .993);
        --violet-4: color(display-p3 .916 .897 1);
        --violet-5: color(display-p3 .876 .851 1);
        --violet-6: color(display-p3 .825 .793 .981);
        --violet-7: color(display-p3 .752 .712 .943);
        --violet-8: color(display-p3 .654 .602 .902);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .381 .306 .741);
        --violet-11: color(display-p3 .383 .317 .702);
        --violet-12: color(display-p3 .179 .15 .359);
      }
    }
  }
}

:root {
  --black-a1: #0000000d;
  --black-a2: #0000001a;
  --black-a3: #00000026;
  --black-a4: #0003;
  --black-a5: #0000004d;
  --black-a6: #0006;
  --black-a7: #00000080;
  --black-a8: #0009;
  --black-a9: #000000b3;
  --black-a10: #000c;
  --black-a11: #000000e6;
  --black-a12: #000000f2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: #0000000d;
      --black-a2: #0000001a;
      --black-a3: #00000026;
      --black-a4: #0003;
      --black-a5: #0000004d;
      --black-a6: #0006;
      --black-a7: #00000080;
      --black-a8: #0009;
      --black-a9: #000000b3;
      --black-a10: #000c;
      --black-a11: #000000e6;
      --black-a12: #000000f2;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --black-a1: color(display-p3 0 0 0 / .05);
        --black-a2: color(display-p3 0 0 0 / .1);
        --black-a3: color(display-p3 0 0 0 / .15);
        --black-a4: color(display-p3 0 0 0 / .2);
        --black-a5: color(display-p3 0 0 0 / .3);
        --black-a6: color(display-p3 0 0 0 / .4);
        --black-a7: color(display-p3 0 0 0 / .5);
        --black-a8: color(display-p3 0 0 0 / .6);
        --black-a9: color(display-p3 0 0 0 / .7);
        --black-a10: color(display-p3 0 0 0 / .8);
        --black-a11: color(display-p3 0 0 0 / .9);
        --black-a12: color(display-p3 0 0 0 / .95);
      }
    }
  }
}

:root, .light, .light-theme {
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --green-1: #fbfefc;
      --green-2: #f4fbf6;
      --green-3: #e6f6eb;
      --green-4: #d6f1df;
      --green-5: #c4e8d1;
      --green-6: #adddc0;
      --green-7: #8eceaa;
      --green-8: #5bb88b;
      --green-9: #30a46c;
      --green-10: #2b9a66;
      --green-11: #00804f;
      --green-12: #193b2d;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --green-1: color(display-p3 .986 .996 .989);
        --green-2: color(display-p3 .963 .983 .967);
        --green-3: color(display-p3 .913 .964 .925);
        --green-4: color(display-p3 .859 .94 .879);
        --green-5: color(display-p3 .796 .907 .826);
        --green-6: color(display-p3 .718 .863 .761);
        --green-7: color(display-p3 .61 .801 .675);
        --green-8: color(display-p3 .451 .715 .559);
        --green-9: color(display-p3 .332 .634 .442);
        --green-10: color(display-p3 .308 .595 .417);
        --green-11: color(display-p3 .19 .5 .32);
        --green-12: color(display-p3 .132 .228 .18);
      }
    }
  }
}

:root, .light, .light-theme {
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --red-1: #fffcfc;
      --red-2: #fff7f7;
      --red-3: #feebec;
      --red-4: #fdd;
      --red-5: #ffcecf;
      --red-6: #fdbdbe;
      --red-7: #f4a9aa;
      --red-8: #eb8e90;
      --red-9: #e5484d;
      --red-10: #dd3e42;
      --red-11: #ce2c31;
      --red-12: #641723;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --red-1: color(display-p3 .998 .989 .988);
        --red-2: color(display-p3 .995 .971 .971);
        --red-3: color(display-p3 .985 .925 .925);
        --red-4: color(display-p3 .999 .866 .866);
        --red-5: color(display-p3 .984 .812 .811);
        --red-6: color(display-p3 .955 .751 .749);
        --red-7: color(display-p3 .915 .675 .672);
        --red-8: color(display-p3 .872 .575 .572);
        --red-9: color(display-p3 .83 .329 .324);
        --red-10: color(display-p3 .798 .294 .285);
        --red-11: color(display-p3 .744 .234 .222);
        --red-12: color(display-p3 .36 .115 .143);
      }
    }
  }
}

:root, .light, .light-theme {
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: #fbfdff;
      --blue-2: #f4faff;
      --blue-3: #e6f4fe;
      --blue-4: #d6eeff;
      --blue-5: #c4e5ff;
      --blue-6: #acd8fc;
      --blue-7: #8ec8f6;
      --blue-8: #5eb1ef;
      --blue-9: #0190ff;
      --blue-10: #0687f0;
      --blue-11: #0073d7;
      --blue-12: #113264;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --blue-1: color(display-p3 .986 .992 .999);
        --blue-2: color(display-p3 .96 .979 .998);
        --blue-3: color(display-p3 .912 .956 .991);
        --blue-4: color(display-p3 .853 .932 1);
        --blue-5: color(display-p3 .788 .894 .998);
        --blue-6: color(display-p3 .709 .843 .976);
        --blue-7: color(display-p3 .606 .777 .947);
        --blue-8: color(display-p3 .451 .688 .917);
        --blue-9: color(display-p3 .247 .556 .969);
        --blue-10: color(display-p3 .234 .523 .912);
        --blue-11: color(display-p3 .15 .44 .84);
        --blue-12: color(display-p3 .102 .193 .379);
      }
    }
  }
}

button, fieldset, input {
  all: unset;
}

.AuthDialogOverlay {
  background-color: var(--black-a9);
  animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  position: fixed;
  inset: 0;
}

.AuthDialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.AuthDialogTitle {
  color: var(--mauve-12);
  margin: 0;
  font-weight: 500;
}

.TabsRoot {
  box-shadow: 0 2px 10px var(--black-a4);
  flex-direction: column;
  width: 300px;
  display: flex;
}

.TabsList {
  border-bottom: 1px solid var(--mauve-6);
  flex-shrink: 0;
  display: flex;
}

.TabsTrigger {
  color: var(--mauve-11);
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  line-height: 1;
  display: flex;
}

.TabsTrigger:first-child {
  border-top-left-radius: 6px;
}

.TabsTrigger:last-child {
  border-top-right-radius: 6px;
}

.TabsTrigger:hover {
  color: var(--violet-11);
}

.TabsTrigger[data-state="active"] {
  color: var(--violet-11);
  box-shadow: inset 0 -1px, 0 1px;
}

.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  background-color: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
  flex-grow: 1;
  padding: 20px;
}

.Text {
  color: var(--mauve-11);
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.5;
}

.Fieldset {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.Label {
  color: var(--violet-12);
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1;
  display: block;
}

.Input {
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  border-radius: 4px;
  flex: 1 0 auto;
  height: 35px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
}

.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.Button {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
}

.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}

.Button.green:hover {
  background-color: var(--green-5);
}

.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}

button {
  all: unset;
}

.TagsDropdownSelectTrigger {
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 35px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.TagsDropdownSelectTrigger:hover {
  background-color: var(--mauve-3);
}

.TagsDropdownSelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.TagsDropdownSelectTrigger[data-placeholder] {
  color: var(--violet-9);
}

.TagsDropdownSelectIcon {
  color: Var(--violet-11);
}

.TagsDropdownSelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.TagsDropdownSelectViewport {
  padding: 5px;
}

.TagsDropdownSelectItem {
  color: var(--violet-11);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.TagsDropdownSelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.TagsDropdownSelectItem[data-highlighted] {
  background-color: var(--violet-9);
  color: var(--violet-1);
  outline: none;
}

.TagsDropdownSelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.TagsDropdownSelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.TagsDropdownSelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.TagsDropdownSelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

/*# sourceMappingURL=index.8295e8c2.css.map */
