@import '/node_modules/@radix-ui/colors/mauve.css';
@import '/node_modules/@radix-ui/colors/violet.css';
@import '/node_modules/@radix-ui/colors/black-alpha.css';
@import '/node_modules/@radix-ui/colors/green.css';
@import '/node_modules/@radix-ui/colors/red.css';
@import '/node_modules/@radix-ui/colors/blue.css';
@import '/node_modules/@radix-ui/colors/black-alpha.css';
@import '/node_modules/@radix-ui/colors/mauve.css';
@import '/node_modules/@radix-ui/colors/violet.css';
/* reset */

button,
fieldset,
input {
  all: unset;
}

.AuthDialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AuthDialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 90vw;
  max-width: 450px;
  /* max-height: 85vh; */
  /* padding: 25px; */
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
/* .DialogContent:focus {
  outline: none;
} */

.AuthDialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve-12);
  /* font-size: 17px; */
}

/* .DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
} */

/* @keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
} */

/* reset */
/* button,
fieldset,
input {
  all: unset;
} */

.TabsRoot {
  display: flex;
  flex-direction: column;
  width: 300px;
  box-shadow: 0 2px 10px var(--black-a4);
}

.TabsList {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid var(--mauve-6);
}

.TabsTrigger {
  font-family: inherit;
  background-color: white;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: var(--mauve-11);
  user-select: none;
}
.TabsTrigger:first-child {
  border-top-left-radius: 6px;
}
.TabsTrigger:last-child {
  border-top-right-radius: 6px;
}
.TabsTrigger:hover {
  color: var(--violet-11);
}
.TabsTrigger[data-state='active'] {
  color: var(--violet-11);
  box-shadow:
    inset 0 -1px 0 0 currentColor,
    0 1px 0 0 currentColor;
}
.TabsTrigger:focus {
  position: relative;
  /* box-shadow: 0 0 0 2px black; */
}

.TabsContent {
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
}
/* .TabsContent:focus {
  box-shadow: 0 0 0 0px black;
} */

.Text {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}

.Fieldset {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Label {
  font-size: 13px;
  line-height: 1;
  margin-bottom: 10px;
  color: var(--violet-12);
  display: block;
}

.Input {
  flex: 1 0 auto;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}
.Button.green:hover {
  background-color: var(--green-5);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}
