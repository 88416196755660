:root, .light, .light-theme {
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --green-1: color(display-p3 0.986 0.996 0.989);
      --green-2: color(display-p3 0.963 0.983 0.967);
      --green-3: color(display-p3 0.913 0.964 0.925);
      --green-4: color(display-p3 0.859 0.94 0.879);
      --green-5: color(display-p3 0.796 0.907 0.826);
      --green-6: color(display-p3 0.718 0.863 0.761);
      --green-7: color(display-p3 0.61 0.801 0.675);
      --green-8: color(display-p3 0.451 0.715 0.559);
      --green-9: color(display-p3 0.332 0.634 0.442);
      --green-10: color(display-p3 0.308 0.595 0.417);
      --green-11: color(display-p3 0.19 0.5 0.32);
      --green-12: color(display-p3 0.132 0.228 0.18);
    }
  }
}