:root, .light, .light-theme {
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --red-1: color(display-p3 0.998 0.989 0.988);
      --red-2: color(display-p3 0.995 0.971 0.971);
      --red-3: color(display-p3 0.985 0.925 0.925);
      --red-4: color(display-p3 0.999 0.866 0.866);
      --red-5: color(display-p3 0.984 0.812 0.811);
      --red-6: color(display-p3 0.955 0.751 0.749);
      --red-7: color(display-p3 0.915 0.675 0.672);
      --red-8: color(display-p3 0.872 0.575 0.572);
      --red-9: color(display-p3 0.83 0.329 0.324);
      --red-10: color(display-p3 0.798 0.294 0.285);
      --red-11: color(display-p3 0.744 0.234 0.222);
      --red-12: color(display-p3 0.36 0.115 0.143);
    }
  }
}