:root, .light, .light-theme {
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --mauve-1: color(display-p3 0.991 0.988 0.992);
      --mauve-2: color(display-p3 0.98 0.976 0.984);
      --mauve-3: color(display-p3 0.946 0.938 0.952);
      --mauve-4: color(display-p3 0.915 0.906 0.925);
      --mauve-5: color(display-p3 0.886 0.876 0.901);
      --mauve-6: color(display-p3 0.856 0.846 0.875);
      --mauve-7: color(display-p3 0.814 0.804 0.84);
      --mauve-8: color(display-p3 0.735 0.728 0.777);
      --mauve-9: color(display-p3 0.555 0.549 0.596);
      --mauve-10: color(display-p3 0.514 0.508 0.552);
      --mauve-11: color(display-p3 0.395 0.388 0.424);
      --mauve-12: color(display-p3 0.128 0.122 0.147);
    }
  }
}