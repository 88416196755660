:root {
  --black-a1: rgba(0, 0, 0, 0.05);
  --black-a2: rgba(0, 0, 0, 0.1);
  --black-a3: rgba(0, 0, 0, 0.15);
  --black-a4: rgba(0, 0, 0, 0.2);
  --black-a5: rgba(0, 0, 0, 0.3);
  --black-a6: rgba(0, 0, 0, 0.4);
  --black-a7: rgba(0, 0, 0, 0.5);
  --black-a8: rgba(0, 0, 0, 0.6);
  --black-a9: rgba(0, 0, 0, 0.7);
  --black-a10: rgba(0, 0, 0, 0.8);
  --black-a11: rgba(0, 0, 0, 0.9);
  --black-a12: rgba(0, 0, 0, 0.95);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: color(display-p3 0 0 0 / 0.05);
      --black-a2: color(display-p3 0 0 0 / 0.1);
      --black-a3: color(display-p3 0 0 0 / 0.15);
      --black-a4: color(display-p3 0 0 0 / 0.2);
      --black-a5: color(display-p3 0 0 0 / 0.3);
      --black-a6: color(display-p3 0 0 0 / 0.4);
      --black-a7: color(display-p3 0 0 0 / 0.5);
      --black-a8: color(display-p3 0 0 0 / 0.6);
      --black-a9: color(display-p3 0 0 0 / 0.7);
      --black-a10: color(display-p3 0 0 0 / 0.8);
      --black-a11: color(display-p3 0 0 0 / 0.9);
      --black-a12: color(display-p3 0 0 0 / 0.95);
    }
  }
}